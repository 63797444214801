import "./ScrollAnimationText.scss";
import { useEffect, useRef, useState } from "preact/compat";
import classNames from "classnames";

import { range } from "../../hooks/useOnScreen.tsx";
import useIsVisible from "../../hooks/useIsVisible.tsx";

export default function ScrollAnimationText({
  text,
  locale,
  classNameWrapper,
  classNameContent,
}: {
  text: string;
  locale: string;
  classNameWrapper?: string;
  classNameContent?: string;
}) {
  const outerRef = useRef<HTMLDivElement>(null);
  const sentencesRef = useRef<HTMLParagraphElement>(null);
  const { isVisible } = useIsVisible(outerRef, {
    threshold: range(0, 1, 0.01, 2),
  });
  const [positions, setPositions] = useState<number[]>([]);
  const [top, setTop] = useState<number>(Number.MAX_VALUE);
  const [centerPoint, setCenterPoint] = useState<number>(0);
  let timeout: ReturnType<typeof setTimeout> | null;

  useEffect(() => {
    function calcPositions() {
      if (!sentencesRef.current) {
        return;
      }
      const { top: topParent } = sentencesRef.current.getBoundingClientRect();
      const array = [];
      for (const rectElement of sentencesRef.current.children) {
        const { top } = rectElement.getBoundingClientRect();
        array.push(top - topParent);
      }
      setPositions(array);
      setCenterPoint(window.innerHeight / 2);
    }
    window.addEventListener("resize", calcPositions);
    calcPositions();
    return () => window.removeEventListener("resize", calcPositions);
  }, [sentencesRef.current]);

  useEffect(() => {
    function throttleCalcTop() {
      if (!timeout) {
        timeout = setTimeout(() => calcTop(), 150);
      }
    }
    function calcTop() {
      timeout = null;
      if (!sentencesRef.current) {
        return;
      }

      const { top } = sentencesRef.current.getBoundingClientRect();
      setTop(top);
    }
    if (isVisible) {
      window.addEventListener("scroll", throttleCalcTop);
      window.addEventListener("resize", calcTop);
    }
    return () => {
      window.removeEventListener("scroll", throttleCalcTop);
      window.removeEventListener("resize", calcTop);
    };
  }, [isVisible]);

  const separator = locale === "ja" ? "。" : ".";
  const sentences = text
    .split(separator)
    .filter((v) => !!v)
    .map((v) => v + `${separator} `);
  return (
    <div className={classNames("sat", classNameWrapper)} ref={outerRef}>
      <div className={classNames("sat__content", classNameContent)}>
        <p className="sat__content_lower" ref={sentencesRef}>
          {sentences.map((item) => (
            <span>{item}</span>
          ))}
        </p>
        <p className="sat__content_upper">
          {sentences.map((item, index) => {
            const isActive =
              positions.length > index && centerPoint > top + positions[index];
            return (
              <span
                className={classNames("sat__sentence", {
                  sat__sentence_active: isActive,
                })}
              >
                {item}
              </span>
            );
          })}
        </p>
      </div>
    </div>
  );
}
